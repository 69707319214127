.license-name-input[disabled]{
    background-color: #777!important;
}
.popup_cont-sk.sidebar-available{
    margin-left: -28%;
}
.tc._60{
    width: 60%;
}
.af-view .tl-2.np{
    color: #FFF!important;
}
.user-search-preview-content{
    height: unset!important;
}
.user-search-preview-content.more-pad{
    padding-top: 70px;
}
.user-search-table-row{
    cursor: pointer;
}
.user-search-preview-block .flex-block-26{
    height: 50px;
}
.user-search-preview-block .flex-block-25{
    height: calc(100% - 50px);
}